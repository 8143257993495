import React from 'react';
import {
  IonBackButton, IonButton, IonButtons,
  IonContent,
  IonHeader, IonIcon, IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import './Page.css';
import Api from "../services/Api";
import {ItemCodeInterface} from "../interfaces/Page";
import {closeCircleOutline} from "ionicons/icons";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ComponentProps {}

interface ComponentState {
  items: Array<ItemCodeInterface>
  itemCodes: Array<ItemCodeInterface>
  itemCode: string
  itemValue: string
  item?: ItemCodeInterface
  editId: number
  edit: boolean
}

export default class EditItems extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()

  state = {
    items: [],
    itemCodes: [],
    itemCode: "",
    itemValue: "",
    editId: 0,
    edit: false
  }

  componentDidMount() {
    this.getItems({})
  }

  getItems = (data: any) => {
    this.api.post('api/items_edit', data).then(response => {
      if (response.ok) {
        response.json().then(j => {
          this.setState({
            items: j.items,
            itemCodes: j.itemCodes,
            itemCode: this.state.itemCode === "" ? j.itemCodes[0].code : this.state.itemCode,
            edit: false
          })
        })
      } else {
        alert("Error: items not found")
      }

    }).catch(reason => {
      alert("Error")
      console.log(reason)
    })
  }

  setCode = (e: any) => {
    this.setState({itemCode: e.target.value})
  }

  openEdit = (editId: number) => {
    let editValue = ""
    if (editId > 0) {
      for (const item of this.state.items as Array<ItemCodeInterface>) {
        if (item.id === editId) {
          editValue = item.value
          break
        }
      }
    }
    this.setState({editId: editId, itemValue: editValue, edit: true})
  }

  saveEdit = (action: string) => {
    const data = {
      id: this.state.editId,
      code: this.state.itemCode,
      value: this.state.itemValue,
      action: action
    }
    this.getItems(data)
  }

  closeEdit = () => {
    this.setState({edit: false})
  }

  updateValue = (e: any) => {
    this.setState({itemValue: e.target.value})
  }

  codeName = (code: string) => {
    for (const itemCode of this.state.itemCodes as Array<ItemCodeInterface>) {
      if (code === itemCode.code) {
        return itemCode.value
      }
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <IonButtons slot="start">
              <IonBackButton className="back_button" defaultHref="/home"/>
            </IonButtons>

            <IonTitle>Edit items</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonModal isOpen={this.state.edit} onDidDismiss={() => this.closeEdit()}>

            <div className="p-8 text_center">
              <div className="text_right cursor-pointer" onClick={() => this.closeEdit()}>
                <IonIcon icon={closeCircleOutline} size="large"></IonIcon>
              </div>
              <h1>{this.state.editId === 0 ? "Add" : "Edit"} {this.codeName(this.state.itemCode)}</h1>
              <input type="text" value={this.state.itemValue} placeholder="Name" className="input_std mt-4"
                     onChange={(e) => this.updateValue(e)}/>

              <div className="mt-8">
                <IonButton onClick={() => this.saveEdit("edit")}>
                  {this.state.editId === 0 ? 'Add' : 'Update'}
                </IonButton>
              </div>

              {
                this.state.editId > 0 &&
                  <div className="mt-16">
                      <IonButton color="danger" onClick={() => this.saveEdit("delete")}>
                          Delete
                      </IonButton>
                  </div>
              }

            </div>
          </IonModal>
          <div className="p-8">
            <div className="flex items-center">
              <h2>Select type</h2>
              <select className="ml-4 select_std" value={this.state.itemCode}
                      onChange={(e => this.setCode(e))}>
                {
                  this.state.itemCodes.map((iCode: ItemCodeInterface, s) => {
                    return (
                      <option key={"item_code_" + s} value={iCode.code}>
                        {iCode.value}
                      </option>
                    )
                  })
                }
              </select>
            </div>

            <div className="mt-4">
              {
                this.state.items.filter((item: ItemCodeInterface) => item.code === this.state.itemCode)
                  .map((item: ItemCodeInterface, i) => {
                    return (
                      <div key={"item_" + item.id} style={{maxWidth: "200px"}} className="cursor-pointer">
                        <div className={'mt-2 staff_box ' + item.code} onClick={() => this.openEdit(item.id)}>
                          {item.value}
                        </div>
                      </div>
                  )
                })
              }
              <IonButton className="mt-8" onClick={() => this.openEdit(0)}>
                Add {this.codeName(this.state.itemCode)}
              </IonButton>
            </div>
          </div>

        </IonContent>
      </IonPage>
    )
  }
}
