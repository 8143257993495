import {ContentInterface} from "../interfaces/Page";
import {ContentListStd, RoomDayInterface} from "../interfaces/Content";


export function getItemName(code: string, content: ContentInterface): string {
  if (content.configuration.itemCodes) {
    for (const itemCode of content.configuration.itemCodes) {
      if (itemCode.code === code) {
        if (itemCode.value.length < 1) {
          return ""
        } else {
          return itemCode.value + ': '
        }
      }
    }
  }
  return ""
}
export function formatHtml(content: ContentInterface): string {
  let html = ""
  if (content.template === "html") {
    html = content.html
  } else {
    if (content.template === "list_std") {
      const data: ContentListStd = content.data
      if (data.name.length > 0) {
        html += "<h2>" + data.name + "</h2><ul>"
      }
      for (const item of data.items) {
        html += "<li>" + item + "</li>"
      }
      html += "</ul>"
    } else if (content.template === "room_day") {
      const data: RoomDayInterface = content.data

      if (data.name.length > 0) {
        html += "<h2 class='text_center'>" + data.name + "</h2>"
      }

      for (const section of data.sections) {
        html += "<div>"
        if (section.name.length > 0) {
          html += "<h3 class='mt-4'>" + section.name + "</h3>"
        }
        for (const item of section.items) {
          html += "<div class='mt-1 staff_box " + item.code + "'>" + getItemName(item.code, content) + item.value + "</div>"
        }
        html += "</div>"
      }
    } else {
      return "Missing template handler for " + content.template
    }
  }
  return html
}
