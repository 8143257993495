import React from 'react';
import {IonButton, IonContent, IonPage, IonIcon} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import '../pages/Page.css';
import Api from "../services/Api";
import {ContentInterface} from "../interfaces/Page";
import {SectionInterface, StaffInterface} from "../interfaces/Content"
import {formatHtml, getItemName} from "../formatting/formatHtml"
import HtmlEdit from "./HtmlEdit";

interface ComponentProps {
  editUrl: string
  close: () => void
}

interface ComponentState {
  content: ContentInterface|null
  lastUpdated: string
  status: string
  admin: boolean
  html: string
  data: any
  items: Array<StaffInterface>
  loaded: boolean
  error: string
}

export default class ContentEdit extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()

  state = {
    lastUpdated: "",
    status: "",
    admin: false,
    content: null,
    html: "",
    data: {},
    loaded: false,
    items: [],
    error: ""
  }

  componentDidMount() {
    this.api.get(this.props.editUrl).then(response => {
      if (response.ok) {
        response.json().then(j => {
          this.setState({
            admin: j.admin,
            content: j.content,
            html: j.content.html,
            data: j.content.data,
            items: j.items,
            loaded: true
          })
        })
      } else {
        if (response.status === 401) {
          this.setState({error: "You do not have access to edit this content."})
        }
      }

    }).catch(reason => {
      alert("Error")
      console.log(reason)
    })
  }

  save = () => {
    const data = {
      html: this.state.html,
      data: this.state.data
    }
    this.api.post(this.props.editUrl, data).then(response => {
      if (response.ok) {
        this.props.close()
      } else {
        alert("Error: page not found")
      }

    }).catch(reason => {
      alert("Error")
      console.log(reason)
    })
  }

  updateHtml = (html: string) => {
    if (this.state.content) {
      const content: ContentInterface = this.state.content
      content.html = html
      this.setState({html: html, content: content})
    }

  }

  updateData = (e: any, name: string) => {
    const data: any = this.state.data
    const value = e.target.value
    if (name === "name") {
      data.name = value
    } else if (name.startsWith("item")) {
      const i = parseInt(name.substring(4))
      data.items[i] = value
    }
    this.setState({data: data})
  }

  addItem = () => {
    const data: any = this.state.data
    data.items.push("")
    this.setState({data: data})
  }

  updateStruct = (e: any, sectionNr: number, itemNr: number) => {
    const data: any = this.state.data
    const value = e.target.value
    if (itemNr > -1) {
      data.sections[sectionNr].items[itemNr].value = value
    } else {
      data.sections[sectionNr].name = value
    }
    this.setState({data: data})
  }

  updateItem = (e: any, sectionNr: number, itemNr: number) => {
    const data: any = this.state.data
    const value = e.target.value
    data.sections[sectionNr].items[itemNr].value = value
    this.setState({data: data})
  }

  noContent = () => {
    return (
      <div className="p-8">
        {
          this.state.error !== "" ? (
            <h3>{this.state.error}</h3>
          ) : (
            <h2>Loading...</h2>
          )
        }
      </div>)
  }

  renderEditContent = () => {

    if (this.state.content === null) {
      {
        {this.renderHeader()}
        this.noContent()
      }
    } else {
      const content: ContentInterface = this.state.content
      const data = content.data
      if (content.template === "room_day") {
        return (
          <div className="p-4">
            {
              this.state.admin ? (
                <input type="text" value={data.name} placeholder="Name of content"
                       onChange={(e) => this.updateData(e, "name")}/>
              ) : (
                <h2>{data.name}</h2>
              )
            }

            {
              data.sections.map((section: SectionInterface, i: number) => {
                return (
                  <div className="mt-8" key={"section" + i}>
                    {
                      this.state.admin ? (
                        <input type="text" value={section.name} placeholder="Section name"
                               onChange={(e) => this.updateStruct(e, i, -1)}/>
                      ) : (
                        <h3>{section.name}</h3>
                      )
                    }
                    <div className="mt-4"/>

                    {
                      section.items.map((staff: StaffInterface, j: number) => {
                        return (
                          <div className="ml-4 mt-2 flex" key={"staff_" + i + "_" + j}>

                            <div style={{minWidth: "140px"}}>{content.configuration.itemCodes[j].value}:&nbsp;</div>
                            <select value={staff.value} className="select_std"
                                    onChange={(e => this.updateItem(e, i, j))}>
                              <option value="-">-</option>
                              {
                                this.state.items.filter((staff: StaffInterface) => staff.code === content.configuration.itemCodes[j].code)
                                  .map((staff: StaffInterface, s) => {
                                  return (
                                    <option key={"staff_" + i + "_" + j + "_" + s}>
                                      {staff.value}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        )
      } else if (content.template === "list_std") {
        return (
          <div>
            <input type="text" value={data.name}
                   onChange={(e) => this.updateData(e, "name")}/>
            {
              data.items.map((item: string, i: number) => {
                const key = "item" + i
                return (
                  <div className="mt-2" key={key}>
                    <input type="text" value={item} key={key} placeholder="Add item name"
                           onChange={(e) => this.updateData(e, key)}/>
                  </div>
                )
              })
            }
            <div className="mt-2" onClick={this.addItem}>Add item</div>
          </div>
        )
      } else if (content.template === "html") {
        return <HtmlEdit html={this.state.html} updateHtml={this.updateHtml}/>
      } else {
        return (
          <div>Unknown template: {content.template}</div>
        )
      }
    }
  }

  renderHeader = () => {
    return (
      <div className="p-4 flex w-full justify-between">
        <h1>Edit content</h1>
        <div className="cursor-pointer" onClick={() => this.props.close()}>
          <IonIcon icon={closeCircleOutline} size="large"></IonIcon>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.content === null) {
      return (
        <IonPage>
          <IonContent>
            {this.renderHeader()}
            {this.noContent()}
          </IonContent>
        </IonPage>
      )
    }
    const content: ContentInterface = this.state.content
    const html = formatHtml(content)
    return (
      <IonPage>
        <IonContent>
          {this.renderHeader()}
          <div className="flex">
            <div className="mr-8 ml-4">
              {this.renderEditContent()}

              <div className="mt-8 text_center">
                <IonButton onClick={this.save}>Save</IonButton>
              </div>
            </div>

            <div className={content.classes}>
              <div dangerouslySetInnerHTML={{__html: html}}/>
            </div>
          </div>
        </IonContent>
      </IonPage>
     )
    }
  }
