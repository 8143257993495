import React from 'react';
import Api from '../services/Api'
import {IonButton} from "@ionic/react";

interface ComponentProps {
  setLogin: () => void
}
interface ComponentState {
  email: string
  password: string
  error: string
}

export default class Login extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()

  state = {
    email: "",
    password: "",
    error: ""
  }

  login = () => {

    const data = {
      email: this.state.email,
      password: this.state.password
    }

    this.api.post('api/login', data).then(response => {
      response.json().then(r => {
        if (r.status != "ok") {
          this.setState({error: r.error})
        } else {
          this.api.logInNoDispatch(r.token)
          this.props.setLogin()
        }

      })
    })
  }

  updateEmail = (value: string) => {
    this.setState({email: value})
  }

  updatePassword = (value: string) => {
    this.setState({password: value})
  }

  keyUp = (k: string) => {
    if (k === "Enter") {
      this.login()
    }
  }

  render () {
    return (
      <div id="container" className="m-4">
        <h1 className="mt-8">Log in below</h1>
        <div className="flex items-center my-8">
          <input type="text" placeholder="email address" className="input_std" size={30}
                 value={this.state.email} onChange={(e) =>
            this.updateEmail(e.target.value)}/>
          <input type="password" placeholder="password" className="input_std ml-4"
                 onKeyDownCapture={(e) => this.keyUp(e.key)}
                 value={this.state.password} onChange={(e) =>
            this.updatePassword(e.target.value)}/>
          <IonButton className="ml-4" onClick={() => this.login()}>Log in</IonButton>
        </div>
        {
          this.state.error.length > 0 &&
            <div className="my-4">{this.state.error}</div>
        }
      </div>
    )
  }
}
