import React from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonRouterLink, IonButton, IonButtons} from '@ionic/react';
import './Home.css';
import Api from "../services/Api";
import Login from "../components/Login"
import {UserInterface} from "../interfaces/User";

interface PageInterface {
  page: string
  updatedAt: string
}

interface ComponentState {
  pages: Array<PageInterface>
  loggedIn: boolean
  user?: UserInterface
}
export default class Home extends React.Component<Record<string, never>, ComponentState> {
  api = Api.getInstance()
  state = {
    pages: [],
    loggedIn: !!this.api.token,
    user: {email: "", admin: false, editUsers: false, editItems: false}
  }

  componentDidMount() {
    if (this.state.loggedIn) {
      this.getPages()
    }
  }

  getPages = () => {
    this.api.get('api/pages').then(response => {
      if (response.ok) {
        response.json().then(r => {
          this.setState({pages: r.pages, loggedIn: true, user: r.user})
        })
      } else {
        if (response.status === 401) {
          this.logOut()
        } else {
          alert("Error: " + response.status)
        }
      }
    })
  }

  setLoggedIn = () => {
    this.getPages()
  }

  logOut = () => {
    this.api.logOut()
    this.setState({loggedIn: false, pages: []})
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>InfoDisplay</IonTitle>
            {
              this.state.loggedIn &&
                <IonButtons slot="end">
                    {this.state.user.email}
                    <IonButton onClick={() => this.logOut()}>Log out</IonButton>
                </IonButtons>
            }

          </IonToolbar>
        </IonHeader>
        <IonContent>
          {
            this.state.loggedIn ? (
              <div className="p-8">
                <h1>Choose page:</h1>
                {
                  this.state.pages.map((page: PageInterface) => {
                    return (
                      <IonRouterLink key={"page_" + page.page} className="mt-8" routerLink={"/page/" + page.page}>
                        <div className="flex items-center mt-4">
                          <h2>{page.page}</h2>
                          {/* <div className="ml-8">{page.updatedAt}</div>*/}
                        </div>
                      </IonRouterLink>
                    )
                  })
                }
                {
                  this.state.user.editItems &&
                    <IonRouterLink routerLink={"/editItems"}>
                        <h2 className="mt-16" >[Edit items]</h2>
                    </IonRouterLink>
                }
              </div>
            ) : (
              <Login setLogin={this.setLoggedIn}/>
            )
          }

        </IonContent>
      </IonPage>
    );
  }

}
