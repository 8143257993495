import React from 'react';
import {IonContent, IonModal, IonPage} from '@ionic/react';
import './Page.css';
import Api from "../services/Api";
import {RouteComponentProps} from "react-router";
import {ContentInterface, RowInterface, ColInterface} from "../interfaces/Page";
import {formatHtml} from "../formatting/formatHtml"
import ContentEdit from "../components/ContentEdit";

interface ComponentProps extends RouteComponentProps<{code: string}>{
  code: never
}

interface ComponentState {
  rows: Array<RowInterface>
  contents: Array<ContentInterface>
  lastUpdated: string
  status: string
  admin: boolean
  editUrl: string
}

export default class Page extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()
  intervalId: any

  state = {
    rows: [],
    contents: [],
    lastUpdated: "",
    status: "",
    admin: false,
    editUrl: "",
  }

  componentDidMount() {
    console.log('------- page did mount')
    this.api.get('api/page/' + this.props.match.params.code).then(response => {
      if (response.ok) {
        response.json().then(j => {
          this.setState({
              rows: j.layout.rows,
              contents: j.contents,
              lastUpdated: j.lastUpdated,
              status: j.status,
              admin: j.admin,
            },
            () => this.startLoop())
        })
      } else {
        alert("Error: page not found")
      }

    }).catch(reason => {
      alert("Error")
      console.log(reason)
    })
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  startLoop = () => {
    this.intervalId = setInterval(this.checkUpdate, 45000)
  }

  stopLoop = () => {
    clearInterval(this.intervalId)
  }

  checkUpdate = () => {
    const url = 'api/page/' + this.props.match.params.code + '?modified_since=' + this.state.lastUpdated
    this.api.get(url).then(response => {
      if (response.ok) {
        response.json().then(j => {
          if (j.status === "no_change") {
            console.log("No change at " + new Date())
          } else {
            this.setState({
              rows: j.layout.rows,
              contents: j.contents,
              lastUpdated: j.lastUpdated,
              status: j.status
            })
          }
        })
      } else {
        alert("Error: page not found")
      }
    }).catch(reason => {
      alert("Error")
      console.log(reason)
    })
  }

  getContent = (code: string):ContentInterface|null => {
    for (const c of this.state.contents as Array<ContentInterface>) {
      if (c.code === code) {
        return c
      }
    }
    return null
  }

  editContent = (code: string) => {
    const url = "api/page_edit/" + this.props.match.params.code + "/" + code
    this.stopLoop()
    this.setState({editUrl: url})
  }

  renderContent = (col: ColInterface) => {
    const content = this.getContent(col.code)
    if (content === null) {
      return (
        <div>No data!</div>
      )
    }

    const html = formatHtml(content)
    const classes = content.classes + " page_admin"
    // if (this.state.admin) {
    //   classes += " page_admin"
    // }
    return (
      <div key={"col_" + col.code}
           style={{width: col.width + "vw"}}
           onClick={() => this.editContent(col.code)}
           className={classes}>
        <div dangerouslySetInnerHTML={{__html: html}}/>
      </div>
    )

  }

  closeEdit = () => {
    this.setState({editUrl: ""})
    this.checkUpdate()
    this.startLoop()
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <IonModal isOpen={this.state.editUrl !== ""} className="modal_large">
            {
              this.state.editUrl !== "" &&
                <ContentEdit editUrl={this.state.editUrl} close={this.closeEdit}/>
            }
          </IonModal>
          {
            this.state.rows.map((row: RowInterface, rowNr) => {
              return (
                <div key={"row_" + rowNr} style={{height: row.height + "vh"}} className="flex">
                  {
                    row.cols.map((col: ColInterface) => {
                      return this.renderContent(col)
                    })
                  }
                </div>
              )
            })
          }
        </IonContent>
      </IonPage>
    )
  }
}
