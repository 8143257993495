let api_endpoint = "http://localhost:8000/"
let system = "develop"

if (process.env.NODE_ENV === 'production') {
    api_endpoint = "https://display.djarv.com/"
    system = "prod"
}

export interface AppConfig {
    API_ENDPOINT: string,
    TOKEN_NAME: string,
    SYSTEM: string,
}

export const CONFIG: AppConfig = {
    API_ENDPOINT : api_endpoint,
    TOKEN_NAME: 'id_token',
    SYSTEM: system
};
