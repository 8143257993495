import {CONFIG} from '../constants'

export default class Api {

  static myInstance: Api;
  private headers: any;
  private baseUrl: string;
  token: string|null

  constructor() {
    this.baseUrl = CONFIG.API_ENDPOINT;
    this.headers = {
      'Content-Type': 'application/json',
    };
    this.token = window.localStorage[CONFIG.TOKEN_NAME];
    if (this.token) {
      this.headers['Authorization'] = 'Bearer ' + this.token;
    }
  }


  /**
   * @returns {ApiService}
   */
  static getInstance(): Api {
    if (Api.myInstance == null) {
      Api.myInstance = new Api();
    }

    return this.myInstance;
  }

  public logInNoDispatch(token: string) {
    window.localStorage[CONFIG.TOKEN_NAME] = token
    this.headers['Authorization'] = 'Bearer ' + token
    this.token = token
  }

  public logOut() {
    delete window.localStorage[CONFIG.TOKEN_NAME]
    this.token = null
  }

  public get(url: string) {

    return fetch(this.baseUrl + url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
    });
  }

  public post(url: string, data: any) {

    return fetch(this.baseUrl + url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  }
}
