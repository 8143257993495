import React, {useRef} from 'react';
import '../pages/Page.css';
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";
// import "quill-emoji/dist/quill-emoji.css";

interface ComponentProps {
  html: string
  updateHtml: (html:string) => void
}

interface ComponentState {
  error: string
}

export default class HtmlEdit extends React.Component<ComponentProps, ComponentState> {

  reactQuillRef: any
  TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["emoji"],
    ["clean"]
  ];
  constructor(props: ComponentProps) {
    super(props)
    this.reactQuillRef = React.createRef()
  }

  state = {
    error: ""
  }

  onChange = (html: string) => {
    this.props.updateHtml(html)
  }

  render() {
    // return (
    //   <textarea onChange={(e) => this.props.updateHtml(e.target.value)}
    //             value={this.props.html} cols={60} rows={10}/>
    // )


    return (
      <ReactQuill
        ref={this.reactQuillRef}
        theme="snow"
        placeholder="Start writing..."
        modules={{
          toolbar: {
            container: this.TOOLBAR_OPTIONS
          },
          "emoji-toolbar": false,
          "emoji-textarea": false,
          "emoji-shortname": false
        }}
        value={this.props.html}
        onChange={this.onChange}
      />

    )
  }
}
